import { DefaultVisibility } from "./dtos/shared/DefaultVisibility";

const DEFAULT_PAGE_SIZE = 15;
const DEFAULT_ROW_VISIBILITY = DefaultVisibility.Tenant;
const MAX_PAGE_SIZE = 100;
const MAX_EXPORT_PAGE_SIZE = 100000;
const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
const MAX_VIEWS_PER_ASSISTANTS = 10000;

const ASSISTANTS_MAX_SECONDS = 120;
const ASSISTANTS_WAIT_SECONDS = 2;
const MAX_ROWS_LENGHT = 10000000;
const MAX_ROWS_MB = 50;
const MAX_FILE_SIZE_MB = 50;
const VERSION = "1.3.0";

export default {
  DEFAULT_PAGE_SIZE,
  DEFAULT_ROW_VISIBILITY,
  MAX_PAGE_SIZE,
  MAX_EXPORT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  VERSION,
  MAX_VIEWS_PER_ASSISTANTS,
  ASSISTANTS_MAX_SECONDS,
  ASSISTANTS_WAIT_SECONDS,
  MAX_ROWS_LENGHT,
  MAX_ROWS_MB,
  MAX_FILE_SIZE_MB,
};
